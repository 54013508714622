
import { Component, Vue, VModel } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Regex from '@/models/Forms/Regex';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import DateSelector from '../Elements/DateSelectorComponent.vue';
import SocialSecurity from '../Elements/SocialSecurityComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { SecurityClearance } from '@/models/Entities/Student/Constants';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import { EMilitaryRank } from '@/enums/ECurrentGrade';

@Component<SecurityInformationFormPartialComponent>({
  components: {
    TextInput,
    DropdownComponent,
    DateSelector,
    SocialSecurity
  }
})
export default class SecurityInformationFormPartialComponent extends Vue {
  @VModel() candidate!: Applicant;

  Regex = Regex;
  section = VITALS_PAGE_SECTIONS.SECURITY;
  rankOptions = getInputOptions(EMilitaryRank);
  securityClearanceOptions = getInputOptions(SecurityClearance);
  yesNoDropDownOptions = yesNoOptions;
}
