var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "bg-purple-100" }, [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(_vm._s(_vm.section.label))]
    ),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("text-input", {
          attrs: { label: "Base" },
          model: {
            value: _vm.candidate.gainingUnitBase,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitBase", $$v)
            },
            expression: "candidate.gainingUnitBase",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.sponsorUnitDropDownItems,
            label: "Sponsor Unit",
          },
          model: {
            value: _vm.candidate.gainingUnitName,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitName", $$v)
            },
            expression: "candidate.gainingUnitName",
          },
        }),
        _c("text-input", {
          attrs: { label: "CC" },
          model: {
            value: _vm.candidate.gainingUnitCommander,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitCommander", $$v)
            },
            expression: "candidate.gainingUnitCommander",
          },
        }),
        _c("phone-input", {
          attrs: { label: "CC Phone" },
          model: {
            value: _vm.candidate.gainingUnitCommanderphone,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitCommanderphone", $$v)
            },
            expression: "candidate.gainingUnitCommanderphone",
          },
        }),
        _c("text-input", {
          attrs: { label: "FSS" },
          model: {
            value: _vm.candidate.gainingUnitForcesupportsquadron,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitForcesupportsquadron", $$v)
            },
            expression: "candidate.gainingUnitForcesupportsquadron",
          },
        }),
        _c("text-input", {
          attrs: { regex: _vm.Regex.email, label: "FSS Email" },
          model: {
            value: _vm.candidate.gainingUnitForcesupportsquadronemail,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "gainingUnitForcesupportsquadronemail",
                $$v
              )
            },
            expression: "candidate.gainingUnitForcesupportsquadronemail",
          },
        }),
        _c("text-input", {
          attrs: { label: "POC" },
          model: {
            value: _vm.candidate.gainingUnitPointofcontact,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitPointofcontact", $$v)
            },
            expression: "candidate.gainingUnitPointofcontact",
          },
        }),
        _c("phone-input", {
          attrs: { label: "POC Phone" },
          model: {
            value: _vm.candidate.gainingUnitPointofcontactphonenumber,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "gainingUnitPointofcontactphonenumber",
                $$v
              )
            },
            expression: "candidate.gainingUnitPointofcontactphonenumber",
          },
        }),
        _c("dropdown-component", {
          attrs: { values: _vm.aircraftOptions, label: "Aircraft" },
          model: {
            value: _vm.candidate.gainingUnitAircraft,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitAircraft", $$v)
            },
            expression: "candidate.gainingUnitAircraft",
          },
        }),
        _c("text-input", {
          attrs: { label: "PAS" },
          model: {
            value: _vm.candidate.gainingUnitGainingpascode,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "gainingUnitGainingpascode", $$v)
            },
            expression: "candidate.gainingUnitGainingpascode",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }