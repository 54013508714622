
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { DropdownOptions, yesNoOptions } from '@/models/Forms/FormOption';
import { BadgeType } from '@/enums/ETrainingTypes';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import { formatDate } from '@/services/formatService';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import ReadOnlyComponent from '../Elements/ReadOnlyComponent.vue';

@Component<ThreeFortiethFormComponent>({
  components: {
    DateSelector,
    DropdownComponent,
    TextInput,
    ReadOnlyComponent
  }
})
export default class ThreeFortiethFormComponent extends Vue {
  @Prop()
  applicant!: Applicant;

  @Prop({ default: () => [] })
  otsClasses!: OfficerTrainingSchoolClass[];

  @Prop({ default: () => [] })
  inProcessingClasses!: InProcessingClass[];

  yesNoDropdownOptions = yesNoOptions;
  badgeDropdownOptions = getInputOptions(BadgeType);

  section = VITALS_PAGE_SECTIONS.WORKFLOW;

  get inProDropdownOptions(): DropdownOptions[] {
    return this.inProcessingClasses.map<DropdownOptions>(
      (inProcessingClass) => {
        return {
          label: formatDate(inProcessingClass.classStartDate),
          value: inProcessingClass.id
        };
      }
    );
  }

  get otsClassDropdownOptions(): DropdownOptions[] {
    return this.otsClasses.map<DropdownOptions>((ots) => {
      return { label: ots.classNumber || '', value: ots.id };
    });
  }

  get inProValue(): number | null {
    if (this.applicant.inProcessingClass) {
      return this.applicant.inProcessingClass.id;
    }
    return null;
  }

  get otsValue(): number | null {
    if (this.applicant.officerTrainingSchoolClass) {
      return this.applicant.officerTrainingSchoolClass.id;
    }
    return null;
  }

  searchOTS(id: number): OfficerTrainingSchoolClass | null {
    return this.otsClasses.find((ots) => ots.id === id) || null;
  }

  searchInPro(id: number): InProcessingClass | null {
    return this.inProcessingClasses.find((inpro) => inpro.id === id) || null;
  }

  async handleInProChange(id: number): Promise<void> {
    this.applicant.inProcessingClass = this.searchInPro(id);
    this.$emit('input', this.applicant.inProcessingClass);
  }

  handleOtsChange(id: number): void {
    this.applicant.officerTrainingSchoolClass = this.searchOTS(id);
    this.$emit('input', this.applicant.officerTrainingSchoolClass);
  }
}
