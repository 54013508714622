var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", { staticClass: "w-full bg-gray-300" }, [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(" " + _vm._s(_vm.section.label) + " ")]
    ),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("text-input", {
          attrs: { placeholder: "United States", label: "Citizenship" },
          model: {
            value: _vm.candidate.demographicsCitizenship,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "demographicsCitizenship", $$v)
            },
            expression: "candidate.demographicsCitizenship",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            label: "Dual citizenship",
            values: _vm.yesNoDropDownOptions,
          },
          model: {
            value: _vm.candidate.demographicsIsdualcitizen,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "demographicsIsdualcitizen", $$v)
            },
            expression: "candidate.demographicsIsdualcitizen",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            label: "Security Clearance Status",
            values: _vm.securityClearanceOptions,
          },
          model: {
            value: _vm.candidate.militaryStatusSecurityclearancev2,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "militaryStatusSecurityclearancev2", $$v)
            },
            expression: "candidate.militaryStatusSecurityclearancev2",
          },
        }),
        _c("date-selector", {
          attrs: { label: "Security close-out" },
          model: {
            value: _vm.candidate.militaryStatusSecuritycloseoutdate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "militaryStatusSecuritycloseoutdate", $$v)
            },
            expression: "candidate.militaryStatusSecuritycloseoutdate",
          },
        }),
        _c("text-input", {
          staticClass: "lg:col-span-2",
          attrs: { label: "PoB" },
          model: {
            value: _vm.candidate.identificationInformationPlaceofbirth,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "identificationInformationPlaceofbirth",
                $$v
              )
            },
            expression: "candidate.identificationInformationPlaceofbirth",
          },
        }),
        _c("dropdown-component", {
          attrs: { label: "Rank", values: _vm.rankOptions },
          model: {
            value: _vm.candidate.militaryStatusRanking,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "militaryStatusRanking", $$v)
            },
            expression: "candidate.militaryStatusRanking",
          },
        }),
        _c("date-selector", {
          attrs: { label: "DoR" },
          model: {
            value: _vm.candidate.militaryStatusDateofrank,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "militaryStatusDateofrank", $$v)
            },
            expression: "candidate.militaryStatusDateofrank",
          },
        }),
        _c("date-selector", {
          attrs: { label: "ETS" },
          model: {
            value: _vm.candidate.militaryStatusEstimatedtimeofseparation,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "militaryStatusEstimatedtimeofseparation",
                $$v
              )
            },
            expression: "candidate.militaryStatusEstimatedtimeofseparation",
          },
        }),
        _c("text-input", {
          attrs: {
            maxlength: "10",
            canHide: true,
            label: "DoD ID",
            type: "number",
          },
          model: {
            value: _vm.candidate.identificationInformationEdipi,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "identificationInformationEdipi", $$v)
            },
            expression: "candidate.identificationInformationEdipi",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }