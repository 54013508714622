var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(_vm._s(_vm.section.label))]
    ),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("dropdown-component", {
          attrs: { values: _vm.badgeDropdownOptions, label: "Badge" },
          model: {
            value: _vm.applicant.militaryStatusBadge,
            callback: function ($$v) {
              _vm.$set(_vm.applicant, "militaryStatusBadge", $$v)
            },
            expression: "applicant.militaryStatusBadge",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.otsClassDropdownOptions,
            value: _vm.otsValue,
            label: "OTS class",
          },
          on: { input: _vm.handleOtsChange },
        }),
        _c("read-only-component", {
          attrs: { label: "UFT Board", value: _vm.applicant.acceptedBoardId },
        }),
        _c("read-only-component", {
          attrs: {
            label: "Prior Service",
            value: _vm.applicant.militaryStatusHaspriorservice,
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.inProDropdownOptions,
            value: _vm.inProValue,
            label: "InPro Group",
          },
          on: { input: _vm.handleInProChange },
        }),
        _c("date-selector", {
          attrs: { label: "Gained" },
          model: {
            value: _vm.applicant.gainsGaindate,
            callback: function ($$v) {
              _vm.$set(_vm.applicant, "gainsGaindate", $$v)
            },
            expression: "applicant.gainsGaindate",
          },
        }),
        _c("dropdown-component", {
          attrs: { values: _vm.yesNoDropdownOptions, label: "CPW" },
          model: {
            value: _vm.applicant.militaryStatusCivilianpathtowings,
            callback: function ($$v) {
              _vm.$set(_vm.applicant, "militaryStatusCivilianpathtowings", $$v)
            },
            expression: "applicant.militaryStatusCivilianpathtowings",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }