var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "flex flex-col w-full mt-8 ml-12 space-y-12" },
    [
      _c("h1", { staticClass: "-mb-4 text-3xl" }, [_vm._v("Vitals")]),
      _c("security-information-form", {
        staticClass: "py-4",
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-col" },
        [
          _c("housing-information-form", {
            staticClass: "py-4 bg-argus-green",
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
          _c("dependents-form", {
            staticClass: "py-4 bg-argus-green",
            model: {
              value: _vm.model,
              callback: function ($$v) {
                _vm.model = $$v
              },
              expression: "model",
            },
          }),
        ],
        1
      ),
      _c("flight-pay-form", {
        staticClass: "py-4 bg-opacity-75 bg-argus-yellow",
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _c("three-fortieth-form", {
        staticClass: "py-4 bg-opacity-75 bg-argus-red",
        attrs: {
          applicant: _vm.model,
          otsClasses: _vm.otsClasses,
          inProcessingClasses: _vm.inProcessingClasses,
        },
        on: { input: _vm.updateModel },
      }),
      _c("prior-air-crew-form", {
        staticClass: "py-4 bg-opacity-25 bg-argus-blue",
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _c("gaining-unit-form", {
        staticClass: "py-4",
        model: {
          value: _vm.model,
          callback: function ($$v) {
            _vm.model = $$v
          },
          expression: "model",
        },
      }),
      _c("sticky-container-component", [
        _c(
          "div",
          {
            staticClass:
              "flex justify-center p-4 mx-4 bg-white border border-gray-300 rounded shadow-xl",
          },
          [
            _c(
              "button-component",
              {
                staticClass: "flex items-center",
                class: { "opacity-50": _vm.isUpdating },
                attrs: {
                  type: "submit",
                  textOrBorderColor: "white",
                  bg: "val-button-blue",
                  disabled: _vm.isUpdating,
                },
                on: { click: _vm.submitForm },
              },
              [
                _vm._v("Update Profile "),
                _vm.isUpdating
                  ? _c("icon-component", {
                      staticClass: "ml-2 transform rotate-180 animate-spin",
                      attrs: { name: "loading", width: 20, height: 20 },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }