var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c("h3", { staticClass: "ml-8 section-header" }, [_vm._v("Dependents")]),
    _c(
      "div",
      { staticClass: "mx-8 mb-2 form-input-container" },
      [
        _c("dropdown-component", {
          attrs: {
            "data-cy": "dependentTypeDropdown",
            values: _vm.dependentTypeOptions,
            placeholder: "Select one",
            label: "Dependents",
          },
          model: {
            value: _vm.applicant.hasDependents,
            callback: function ($$v) {
              _vm.$set(_vm.applicant, "hasDependents", $$v)
            },
            expression: "applicant.hasDependents",
          },
        }),
      ],
      1
    ),
    _vm.showDependents
      ? _c(
          "div",
          { staticClass: "mx-8" },
          [
            _vm._l(_vm.applicant.dependents, function (_, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "mb-2 form-input-container lg:grid-cols-5",
                },
                [
                  _c("text-input", {
                    attrs: {
                      "data-cy": `dependentName-${index}`,
                      label: "name",
                    },
                    model: {
                      value: _vm.applicant.dependents[index].name,
                      callback: function ($$v) {
                        _vm.$set(_vm.applicant.dependents[index], "name", $$v)
                      },
                      expression: "applicant.dependents[index].name",
                    },
                  }),
                  _c("dropdown-component", {
                    attrs: {
                      values: _vm.familyRelationOptions,
                      "data-cy": `dependentFamilyRelationDropdown-${index}`,
                      placeholder: "Select one",
                      label: "Relationship",
                    },
                    model: {
                      value: _vm.applicant.dependents[index].relationship,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applicant.dependents[index],
                          "relationship",
                          $$v
                        )
                      },
                      expression: "applicant.dependents[index].relationship",
                    },
                  }),
                  _c("date-selector", {
                    attrs: {
                      "data-cy": `dependentDateOfBirth-${index}`,
                      label: "DoB",
                    },
                    model: {
                      value: _vm.applicant.dependents[index].dateOfBirth,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applicant.dependents[index],
                          "dateOfBirth",
                          $$v
                        )
                      },
                      expression: "applicant.dependents[index].dateOfBirth",
                    },
                  }),
                  _c("phone-input", {
                    attrs: {
                      "data-cy": `dependentPhoneNumber-${index}`,
                      placeholder: "Enter a phone number",
                      label: "Phone number",
                    },
                    model: {
                      value: _vm.applicant.dependents[index].phoneNumber,
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.applicant.dependents[index],
                          "phoneNumber",
                          $$v
                        )
                      },
                      expression: "applicant.dependents[index].phoneNumber",
                    },
                  }),
                  _c(
                    "button-component",
                    {
                      staticClass: "mt-4 h-9",
                      on: {
                        click: function ($event) {
                          return _vm.removeDependent(index)
                        },
                      },
                    },
                    [_vm._v(" Remove Dependent ")]
                  ),
                ],
                1
              )
            }),
            _c(
              "button-component",
              {
                staticClass: "mt-4",
                attrs: { "data-cy": "addDependentButton" },
                on: { click: _vm.addDependent },
              },
              [_vm._v(" Add Dependent ")]
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }