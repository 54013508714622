
import { Component, Vue, VModel } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import Dependent from '@/models/Entities/Dependent/Dependent';
import TextInput from '../Elements/TextInputComponent.vue';
import { Applicant } from '@/models/Entities/Personas/Applicant';
import { EFamilyRelations } from '@/enums/EFamilyRelations';
import DateSelector from '../Elements/DateSelectorComponent.vue';
import PhoneInput from '../Elements/PhoneInputComponent.vue';
import Icon from '@/components/IconComponent.vue';
import { EDependentsTypes } from '@/enums/EDependentsTypes';
import { getInputOptions } from '@/util/getInputOptions';

@Component<DependentsFormPartialComponent>({
  components: {
    DropdownComponent,
    ButtonComponent,
    TextInput,
    DateSelector,
    PhoneInput,
    Icon
  }
})
export default class DependentsFormPartialComponent extends Vue {
  @VModel() applicant!: Applicant;

  dependentTypeOptions = getInputOptions(EDependentsTypes);
  familyRelationOptions = getInputOptions(EFamilyRelations);

  addDependent(): void {
    this.applicant.dependents.push(new Dependent());
  }

  removeDependent(index: number): void {
    this.applicant.dependents.splice(index, 1);
  }

  get showDependents(): boolean {
    const typesWithDependents = [
      EDependentsTypes.SINGLE_WITH_DEPENDENTS,
      EDependentsTypes.MARRIED_WITH_DEPENDENTS,
      EDependentsTypes.MARRIED,
      EDependentsTypes.MIL_TO_MIL_WITH_DEPENENTS
    ];
    return typesWithDependents.includes(
      this.applicant.hasDependents as EDependentsTypes
    );
  }
}
