
import ButtonComponent from '@/components/ButtonComponent.vue';
import ThreeFortiethForm from '@/components/Forms/Partials/340thFormPartialComponent.vue';
import DependentsForm from '@/components/Forms/Partials/DependentsFormPartialComponent.vue';
import FlightPayForm from '@/components/Forms/Partials/FlightPayFormPartialComponent.vue';
import GainingUnitForm from '@/components/Forms/Partials/GainingUnitFormPartialComponent.vue';
import HousingInformationForm from '@/components/Forms/Partials/HousingInformationFormPartialComponent.vue';
import PriorAirCrewForm from '@/components/Forms/Partials/PriorAirCrewFormPartialComponent.vue';
import SecurityInformationForm from '@/components/Forms/Partials/SecurityInformationFormPartialComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import StickyContainerComponent from '@/components/StickyContainerComponent.vue';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { OfficerTrainingSchoolClass } from '@/models/Entities/Course/OfficerTrainingSchoolClass';
import { InProcessingClass } from '@/models/Entities/Student/InProcessingClass';
import VitalsPageModel, {
  VITALS_PAGE_SECTIONS
} from '@/models/Profile/VitalsPageModel';
import ProfileDetailsBaseComponent from '@/pages/Applicant/Details/ProfileDetailsBaseComponent.vue';
import generateScrollToCallback from '@/util/generateScrollToCallback';
import {
  Component,
  Emit,
  InjectReactive,
  Prop,
  Vue,
  Watch
} from 'vue-property-decorator';

@Component<VitalsPage>({
  components: {
    ProfileDetailsBaseComponent,
    SecurityInformationForm,
    FlightPayForm,
    HousingInformationForm,
    DependentsForm,
    PriorAirCrewForm,
    GainingUnitForm,
    ThreeFortiethForm,
    StickyContainerComponent,
    ButtonComponent,
    IconComponent
  }
})
export default class VitalsPage extends Vue {
  @Prop({ default: new BaseCandidate() })
  applicant!: BaseCandidate;

  @Prop({ default: () => [] })
  inProcessingClasses!: InProcessingClass[];

  @Prop({ default: () => [] })
  otsClasses!: OfficerTrainingSchoolClass[];

  @InjectReactive('isUpdating')
  isUpdating!: boolean;

  model = new VitalsPageModel();

  sections = VITALS_PAGE_SECTIONS;

  created(): void {
    this.model = new VitalsPageModel(this.applicant);
    this.setSectionStatus();
  }

  updateModel(model: VitalsPageModel): void {
    this.$emit('input', model);
  }

  @Watch('applicant', { immediate: true })
  setSectionStatus(): void {
    const {
      SECURITY,
      BASIC_ALLOWANCE_FOR_HOUSING,
      FLIGHT_PAY_HOLD_INFO,
      WORKFLOW,
      TRAINING_HISTORY,
      SPONSORING_UNIT
    } = this.sections;

    this.$store.dispatch('profileSideNavigationModule/setSections', [
      {
        header: 'Vitals',
        entries: [
          {
            text: SECURITY.label,
            status: this.model.securitySectionStatus,
            onClick: generateScrollToCallback(SECURITY.key)
          },
          {
            text: BASIC_ALLOWANCE_FOR_HOUSING.label,
            status: this.model.housingSectionStatus,
            onClick: generateScrollToCallback(BASIC_ALLOWANCE_FOR_HOUSING.key)
          },
          {
            text: FLIGHT_PAY_HOLD_INFO.label,
            status: this.model.flightPayHoldInfoSectionStatus,
            onClick: generateScrollToCallback(FLIGHT_PAY_HOLD_INFO.key)
          },
          {
            text: WORKFLOW.label,
            status: this.model.workflowSectionStatus,
            onClick: generateScrollToCallback(WORKFLOW.key)
          },
          {
            text: TRAINING_HISTORY.label,
            status: this.model.trainingHistorySectionStatus,
            onClick: generateScrollToCallback(TRAINING_HISTORY.key)
          },
          {
            text: SPONSORING_UNIT.label,
            status: this.model.sponsoringUnitSectionStatus,
            onClick: generateScrollToCallback(SPONSORING_UNIT.key)
          }
        ]
      }
    ]);
  }

  @Emit('updateApplicant')
  submitForm(): BaseCandidate {
    return this.model;
  }
}
