
import { Component, Vue, VModel } from 'vue-property-decorator';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { EHoldTypes } from '@/enums/EHoldTypes';
import { getInputOptions } from '@/util/getInputOptions';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';

@Component<FlightPayFormPartialComponent>({
  components: {
    DateSelector,
    DropdownComponent
  }
})
export default class FlightPayFormPartialComponent extends Vue {
  @VModel() candidate!: BaseCandidate;

  section = VITALS_PAGE_SECTIONS.FLIGHT_PAY_HOLD_INFO;

  holdDropdownOptions = getInputOptions(EHoldTypes);
}
