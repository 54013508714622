/** @deprecate */
export enum ECurrentGrade {
  AIRMAN = 'AMN',
  AIRMAN_BASIC = 'AB',
  AIRMAN_SENIOR = 'SRA',
  FirstLT = '1LT',
  SecondLT = '2LT',
  A1C = 'A1C',
  CPT = 'CPT',
  LTC = 'LTC',
  MAJ = 'MAJ',
  MSG = 'MSG',
  SMS = 'SMS',
  SSG = 'SSG',
  TSG = 'TSG'
}

/** key begins with the letter 'O' */
export enum EAirForceGrade {
  O1 = 'O1',
  O2 = 'O2',
  O3 = 'O3',
  O4 = 'O4',
  O5 = 'O5',
  E1 = 'E1',
  E2 = 'E2',
  E3 = 'E3',
  E4 = 'E4',
  E5 = 'E5',
  E6 = 'E6',
  E7 = 'E7',
  E8 = 'E8',
  E9 = 'E9'
}

/**
 * Also used to map grade to rank
 * CMSgt_CCM covers both E9 scenarios,
 */
export enum EMilitaryRank {
  _1LT = '1LT',
  _2LT = '2LT',
  AB = 'AB',
  Amn = 'Amn',
  A1C = 'A1C',
  CCM = 'CCM',
  CMSgt = 'CMSgt',
  CMSgt_CCM = 'CMSgt/CCM',
  CPT = 'CPT',
  LTC = 'LTC',
  MAJ = 'MAJ',
  MSgt = 'MSgt',
  SMSgt = 'SMSgt',
  SrA = 'SrA',
  SSgt = 'SSgt',
  TSgt = 'TSgt'
}
