
import { Component, Vue, VModel } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import Regex from '@/models/Forms/Regex';
import DropdownComponent from '../Elements/DropdownComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import { MwsType } from '@/models/Entities/Student/Constants';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import { ESponsorUnits } from '@/enums';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';

@Component<GainingUnitFormPartial>({
  components: {
    PhoneInput,
    TextInput,
    DropdownComponent
  }
})
export default class GainingUnitFormPartial extends Vue {
  @VModel() candidate!: BaseCandidate;

  Regex = Regex;

  section = VITALS_PAGE_SECTIONS.SPONSORING_UNIT;

  sponsorUnitDropDownItems = getInputOptions(ESponsorUnits);
  aircraftOptions = getInputOptions(MwsType);
}
