var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(" Basic Allowance for Housing ")]
    ),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("dropdown-component", {
          attrs: {
            values: _vm.yesNoDropDownOptions,
            label: "BAH Waiver",
            placeholder: "Select",
          },
          model: {
            value: _vm.candidate.housingInformationBasicallowanceforhousing,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "housingInformationBasicallowanceforhousing",
                $$v
              )
            },
            expression: "candidate.housingInformationBasicallowanceforhousing",
          },
        }),
        _c("date-selector", {
          attrs: { label: "Waiver Log Notificiation" },
          model: {
            value: _vm.candidate.housingInformationWaiverlognotification,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "housingInformationWaiverlognotification",
                $$v
              )
            },
            expression: "candidate.housingInformationWaiverlognotification",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.yesNoDropDownOptions,
            label: "Valid",
            placeholder: "Select",
          },
          model: {
            value: _vm.candidate.housingInformationIsvalid,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "housingInformationIsvalid", $$v)
            },
            expression: "candidate.housingInformationIsvalid",
          },
        }),
        _c("date-selector", {
          attrs: { label: "Term Routed" },
          model: {
            value: _vm.candidate.housingInformationTermrouted,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "housingInformationTermrouted", $$v)
            },
            expression: "candidate.housingInformationTermrouted",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }