
import { Component, Vue, VModel } from 'vue-property-decorator';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';

@Component<HousingInformationFormPartialComponent>({
  components: {
    DropdownComponent,
    DateSelector
  }
})
export default class HousingInformationFormPartialComponent extends Vue {
  @VModel() candidate!: BaseCandidate;

  section = VITALS_PAGE_SECTIONS.BASIC_ALLOWANCE_FOR_HOUSING;
  yesNoDropDownOptions = yesNoOptions;
}
