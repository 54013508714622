import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { getSectionStatus } from '@/util/getSectionStatus';
import { convertToKeyValueObj } from '@/util/object/convertToKeyValueObj';
import { ESectionStatus } from '../../enums/ESectionStatus';

export const VITALS_PAGE_SECTIONS = convertToKeyValueObj({
  SECURITY: 'Security',
  BASIC_ALLOWANCE_FOR_HOUSING: 'BAH',
  FLIGHT_PAY_HOLD_INFO: 'Flight Pay / Hold',
  WORKFLOW: 'Workflow',
  TRAINING_HISTORY: 'Training History',
  SPONSORING_UNIT: 'Sponsoring Unit'
});

export default class VitalsPageModel extends BaseCandidate {
  statusFunction: (fields: unknown[]) => ESectionStatus;

  get securitySectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.demographicsCitizenship,
      this.demographicsIsdualcitizen,
      this.militaryStatusSecurityclearancev2,
      this.militaryStatusSecuritycloseoutdate,
      this.identificationInformationPlaceofbirth,
      this.militaryStatusRanking,
      this.militaryStatusDateofrank,
      this.militaryStatusEstimatedtimeofseparation,
      this.identificationInformationEdipi
    ]);
  }

  get housingSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.housingInformationWaiverlognotification,
      this.housingInformationIsvalid,
      this.housingInformationTermrouted,
      this.hasDependents
    ]);
  }

  get flightPayHoldInfoSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.flightPayCmsopendate,
      this.flightPayCmsclosedate,
      this.flightPayAorecieveddate
    ]);
  }

  get workflowSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.militaryStatusBadge,
      this.officerTrainingSchoolClass,
      this.militaryStatusHaspriorservice,
      this.gainsGaindate,
      this.militaryStatusCivilianpathtowings
    ]);
  }

  get trainingHistorySectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.priorAirCrewSv80acompleted,
      this.priorAirCrewSv83acompleted,
      this.priorAirCrewSv84afcompleted,
      this.priorAirCrewSv85acompleted,
      this.priorAirCrewSv88alcompleted,
      this.priorAirCrewSv90acompleted,
      this.priorAirCrewSv97acompleted,
      this.priorAirCrewSv98acompleted,
      this.priorAirCrewIsprioraircrew,
      this.priorAirCrewQuantitativescore,
      this.priorAirCrewVerbalscore,
      this.priorAirCrewAascore,
      this.priorAirCrewNavscore,
      this.priorAirCrewPilotscore,
      this.priorAirCrewPcsmscore,
      this.priorAirCrewHasprivatelicense,
      this.priorAirCrewRating,
      this.priorAirCrewTotalcivilianflighthours,
      this.sereCompleted,
      this.trainingFirstBasePreference,
      this.availableTrainingStartDate
    ]);
  }

  get sponsoringUnitSectionStatus(): ESectionStatus {
    return this.statusFunction([
      this.gainingUnitBase,
      this.gainingUnitName,
      this.gainingUnitCommander,
      this.gainingUnitCommander,
      this.gainingUnitForcesupportsquadron,
      this.gainingUnitForcesupportsquadronemail,
      this.gainingUnitPointofcontact,
      this.gainingUnitPointofcontact,
      this.gainingUnitAircraft,
      this.gainingUnitGainingpascode
    ]);
  }

  constructor(
    partial?: Partial<BaseCandidate>,
    statusFunction = getSectionStatus
  ) {
    super(partial);
    this.statusFunction = statusFunction;
  }
}
