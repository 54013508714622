var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(_vm._s(_vm.section.label))]
    ),
    _c("label", { staticClass: "ml-8" }, [_vm._v("completed")]),
    _c("div", { staticClass: "flex flex-wrap px-4 py-2 mx-8 mb-4 bg-white" }, [
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv80A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv80acompleted,
              expression: "candidate.priorAirCrewSv80acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv80acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv80acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv80acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv80acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv80acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv80acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv80acompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv83A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv83acompleted,
              expression: "candidate.priorAirCrewSv83acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv83acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv83acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv83acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv83acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv83acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv83acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv83acompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv84AF")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv84afcompleted,
              expression: "candidate.priorAirCrewSv84afcompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv84afcompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv84afcompleted, null) > -1
              : _vm.candidate.priorAirCrewSv84afcompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv84afcompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv84afcompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv84afcompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv84afcompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv85A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv85acompleted,
              expression: "candidate.priorAirCrewSv85acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv85acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv85acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv85acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv85acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv85acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv85acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv85acompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv88AL")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv88alcompleted,
              expression: "candidate.priorAirCrewSv88alcompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv88alcompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv88alcompleted, null) > -1
              : _vm.candidate.priorAirCrewSv88alcompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv88alcompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv88alcompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv88alcompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv88alcompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv90A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv98acompleted,
              expression: "candidate.priorAirCrewSv98acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv98acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv98acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv98acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv98acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv98acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv98acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv98acompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv97A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv97acompleted,
              expression: "candidate.priorAirCrewSv97acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv97acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv97acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv97acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv97acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv97acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv97acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv97acompleted", $$c)
              }
            },
          },
        }),
      ]),
      _c("div", { staticClass: "flex flex-row-reverse items-center mr-4" }, [
        _c("label", { staticClass: "ml-2 uppercase" }, [_vm._v("sv98A")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.candidate.priorAirCrewSv98acompleted,
              expression: "candidate.priorAirCrewSv98acompleted",
            },
          ],
          attrs: { type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.candidate.priorAirCrewSv98acompleted)
              ? _vm._i(_vm.candidate.priorAirCrewSv98acompleted, null) > -1
              : _vm.candidate.priorAirCrewSv98acompleted,
          },
          on: {
            change: function ($event) {
              var $$a = _vm.candidate.priorAirCrewSv98acompleted,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv98acompleted",
                      $$a.concat([$$v])
                    )
                } else {
                  $$i > -1 &&
                    _vm.$set(
                      _vm.candidate,
                      "priorAirCrewSv98acompleted",
                      $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                    )
                }
              } else {
                _vm.$set(_vm.candidate, "priorAirCrewSv98acompleted", $$c)
              }
            },
          },
        }),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("dropdown-component", {
          attrs: {
            values: _vm.yesNoDropDownOptions,
            placeholder: "Select one",
            label: "Prior Aircrew",
          },
          model: {
            value: _vm.candidate.priorAirCrewIsprioraircrew,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewIsprioraircrew", $$v)
            },
            expression: "candidate.priorAirCrewIsprioraircrew",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "Quant",
          },
          model: {
            value: _vm.candidate.priorAirCrewQuantitativescore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewQuantitativescore", $$v)
            },
            expression: "candidate.priorAirCrewQuantitativescore",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "Verb",
          },
          model: {
            value: _vm.candidate.priorAirCrewVerbalscore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewVerbalscore", $$v)
            },
            expression: "candidate.priorAirCrewVerbalscore",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "AA",
          },
          model: {
            value: _vm.candidate.priorAirCrewAascore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewAascore", $$v)
            },
            expression: "candidate.priorAirCrewAascore",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "NAV",
          },
          model: {
            value: _vm.candidate.priorAirCrewNavscore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewNavscore", $$v)
            },
            expression: "candidate.priorAirCrewNavscore",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            max: 100,
            min: 0,
            placeholder: "Enter a number from 0-100",
            label: "Pilot",
          },
          model: {
            value: _vm.candidate.priorAirCrewPilotscore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewPilotscore", $$v)
            },
            expression: "candidate.priorAirCrewPilotscore",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "PCSM",
          },
          model: {
            value: _vm.candidate.priorAirCrewPcsmscore,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewPcsmscore", $$v)
            },
            expression: "candidate.priorAirCrewPcsmscore",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.yesNoDropDownOptions,
            placeholder: "Select one",
            label: "PPL",
          },
          model: {
            value: _vm.candidate.priorAirCrewHasprivatelicense,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewHasprivatelicense", $$v)
            },
            expression: "candidate.priorAirCrewHasprivatelicense",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.ratingOptions,
            placeholder: "Select one",
            label: "Rating",
          },
          model: {
            value: _vm.candidate.priorAirCrewRating,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "priorAirCrewRating", $$v)
            },
            expression: "candidate.priorAirCrewRating",
          },
        }),
        _c("number-input", {
          attrs: {
            type: "number",
            min: 0,
            max: 100,
            placeholder: "Enter a number from 0-100",
            label: "Civ Hours",
          },
          model: {
            value: _vm.candidate.priorAirCrewTotalcivilianflighthours,
            callback: function ($$v) {
              _vm.$set(
                _vm.candidate,
                "priorAirCrewTotalcivilianflighthours",
                $$v
              )
            },
            expression: "candidate.priorAirCrewTotalcivilianflighthours",
          },
        }),
        _c("dropdown-component", {
          attrs: { values: _vm.yesNoDropdownOptions, label: "SERE Complete" },
          model: {
            value: _vm.candidate.sereCompleted,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "sereCompleted", $$v)
            },
            expression: "candidate.sereCompleted",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.baseOptions,
            placeholder: "Select one",
            label: "Base of Preference",
          },
          model: {
            value: _vm.candidate.trainingFirstBasePreference,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "trainingFirstBasePreference", $$v)
            },
            expression: "candidate.trainingFirstBasePreference",
          },
        }),
        _c("date-selector", {
          attrs: { label: "Date Available to Start Training" },
          model: {
            value: _vm.candidate.availableTrainingStartDate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "availableTrainingStartDate", $$v)
            },
            expression: "candidate.availableTrainingStartDate",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }