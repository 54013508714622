var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("section", [
    _c(
      "h3",
      { staticClass: "ml-8 section-header", attrs: { id: _vm.section.key } },
      [_vm._v(_vm._s(_vm.section.label))]
    ),
    _c(
      "div",
      { staticClass: "mx-8 form-input-container" },
      [
        _c("date-selector", {
          attrs: { label: "CMS Open" },
          model: {
            value: _vm.candidate.flightPayCmsopendate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "flightPayCmsopendate", $$v)
            },
            expression: "candidate.flightPayCmsopendate",
          },
        }),
        _c("date-selector", {
          attrs: { label: "CMS Close" },
          model: {
            value: _vm.candidate.flightPayCmsclosedate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "flightPayCmsclosedate", $$v)
            },
            expression: "candidate.flightPayCmsclosedate",
          },
        }),
        _c("date-selector", {
          attrs: { label: "AO Received" },
          model: {
            value: _vm.candidate.flightPayAorecieveddate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "flightPayAorecieveddate", $$v)
            },
            expression: "candidate.flightPayAorecieveddate",
          },
        }),
        _c("date-selector", {
          attrs: { label: "Hold Effective" },
          model: {
            value: _vm.candidate.flightPayHoldeffectivedate,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "flightPayHoldeffectivedate", $$v)
            },
            expression: "candidate.flightPayHoldeffectivedate",
          },
        }),
        _c("dropdown-component", {
          attrs: {
            values: _vm.holdDropdownOptions,
            placeholder: "Select one",
            label: "Hold Type",
          },
          model: {
            value: _vm.candidate.flightPayHoldtype,
            callback: function ($$v) {
              _vm.$set(_vm.candidate, "flightPayHoldtype", $$v)
            },
            expression: "candidate.flightPayHoldtype",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }