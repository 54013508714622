
import { Component, Prop, Vue, VModel } from 'vue-property-decorator';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import Regex from '@/models/Forms/Regex';
import DropdownComponent from '@/components/Forms/Elements/DropdownComponent.vue';
import { yesNoOptions } from '@/models/Forms/FormOption';
import NumberInput from '@/components/Forms/Elements/NumberInputComponent.vue';
import IconComponent from '../../IconComponent.vue';
import { getInputOptions } from '@/util/getInputOptions';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import { PriorAirCrewRatings } from '@/models/Entities/Student/Constants';
import { VITALS_PAGE_SECTIONS } from '@/models/Profile/VitalsPageModel';
import { BaseCandidate } from '@/models/Entities/BaseCandidate';
import { EPreferredUptBaseTypes } from '@/enums';

@Component<PriorAirCrewFormPartialComponent>({
  components: {
    TextInput,
    DropdownComponent,
    NumberInput,
    IconComponent,
    DateSelector
  }
})
export default class PriorAirCrewFormPartialComponent extends Vue {
  @VModel() candidate!: BaseCandidate;

  @Prop()
  readonly title?: string;

  Regex = Regex;

  yesNoDropdownOptions = yesNoOptions;

  section = VITALS_PAGE_SECTIONS.TRAINING_HISTORY;

  ratingOptions = getInputOptions(PriorAirCrewRatings);

  baseOptions = getInputOptions(EPreferredUptBaseTypes);

  yesNoDropDownOptions = yesNoOptions;
}
